import CardTypesTentativeEvent from './CardTypesTentativeEvent';

export default {
  name: 'CardTypesTentativeEvents',
  props: {
    event: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  render() {
    const eachEv = this.event.mySch.schedule.events;
    return (
      <div class="c-select-room__box" style="margin-top: 0;">
        <ul class="c-select-list c-modal-list" style="padding-left: 30px;">
          {eachEv.map((ev, i) => {
            return <CardTypesTentativeEvent event={eachEv} index={i} editable={this.editable} />;
          })}
        </ul>
      </div>
    );
  },
};
