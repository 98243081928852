export default {
  name: 'CalendarEventSpace',
  props: {
    date: {
      type: Date,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  render() {
    const event = this.event;
    const start = new Date(event.start_time.replace(/-/g, '/'));
    const end = new Date(event.end_time.replace(/-/g, '/'));
    const startMin = start.getDate() < this.date.getDate() ? 0 : start.getHours() * 60 + start.getMinutes();
    const endMin = Math.max(end.getHours() * 60 + end.getMinutes(), startMin + 30);
    const style = {
      position: 'absolute',
      top: '50%',
      bottom: '50%',
      left: `calc(${(startMin / 1440) * 100}% - 1px)`,
      right: `calc(${(1 - endMin / 1440) * 100}% + 1px)`,
      height: '35px',
      marginTop: '-17.5px',
      background: event.color,
      pointerEvents: 'none',
      borderRadius: '2px',
      borderLeft: '1px solid #fff',
      borderRight: '1px solid #fff',
    };

    return <div style={style} data-start={event.start_time} data-end={event.end_time} />;
  },
};
