export default {
  /** @type {number|null} */
  target: null,
  /** @type {{}|null} */
  times: null,
  /** @type {boolean|null} */
  _moves: null,
  /** @type {{}|null} */
  candidate: null,
  /** @type {string|null} */
  status: null,
  /** @type {boolean} */
  isMoved: false,
  /** @type {boolean} */
  isScrolled: false,
  /** @type {number|null} */
  timer: null,
  /** @type {Element|null} */
  tips: null,
  /** @type {number|null} */
  Y: null,
  /** @type {boolean|null} */
  hasSearch: null,
  /** @type {Function|null} */
  onChangeMove: null,
  /** @type {string|null} */
  scrollMode: null,
  get moves() {
    return this._moves;
  },
  set moves(prop) {
    if (this._moves === prop) return;
    this._moves = prop;
    this.onChangeMove && this.onChangeMove(Boolean(prop));
  },
  reset(timerId) {
    this.target = null;
    this.times = null;
    this.moves = null;
    this.candidate = null;
    this.status = null;
    this.isMoved = false;
    if (!timerId || this.timer === timerId) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.tips = null;
    this.Y = null;
    this.hasSearch = null;
    this.isScrolled = false;
    this.scrollMode = null;
  },
};
