/** @type {[{label: string, value: string}]} */
const times = new Array(24)
  .fill(null)
  .map((n, i) => {
    const view = i.toString().padStart(2, '0');
    return [
      { label: `${view}:00`, value: `${i}:0` },
      { label: `${view}:15`, value: `${i}:15` },
      { label: `${view}:30`, value: `${i}:30` },
      { label: `${view}:45`, value: `${i}:45` },
    ];
  })
  .flat();

times.push({ label: '24:00', value: '24:0' });

export const hours = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
];

export const minutes = [
  { label: '0', value: 0 },
  { label: '15', value: 15 },
  { label: '30', value: 30 },
  { label: '45', value: 45 },
];

export default times;
