export default {
  name: 'CardTypesTentativeEditButton',
  props: {
    editable: Boolean,
  },
  render() {
    return (
      <div>
        {this.editable && <p class="c-myreserve-cancel__btn" onClick={this.$listeners.click} style="z-index: 50;" />}
        {!this.editable && (
          <p class="c-myreserve-edit__btn" onClick={this.$listeners.click} style="z-index: 50;">
            <svg class="c-myreserve-edit__svg" xmlns="http://www.w3.org/2000/svg" id="create-24px" width="18" height="18" viewBox="0 0 24 24">
              <path
                id="パス_80"
                data-name="パス 80"
                d="M3,17.25V21H6.75L17.81,9.94,14.06,6.19ZM20.71,7.04a1,1,0,0,0,0-1.41L18.37,3.29a1,1,0,0,0-1.41,0L15.13,5.12l3.75,3.75,1.83-1.83Z"
                fill=""
              />
              <path id="パス_81" data-name="パス 81" d="M0,0H24V24H0Z" fill="none" />
            </svg>
          </p>
        )}
      </div>
    );
  },
};
