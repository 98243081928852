import TheCalender from './TheCalender';

export default {
  name: 'TheRoomReservation',
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.tabs = this.$parent.$refs.tabs.$refs.tabs;
    this.setPanelListHeight();
  },
  updated() {
    this.setPanelListHeight();
  },
  methods: {
    show() {
      this.$modal.show('TheModalReservation');
    },
    setPanelListHeight() {
      if (this.$refs.panelList) {
        this.$refs.panelList.style.top = this.tabs.clientHeight + 'px';
      }
    },
  },
  render() {
    if (!this.loading) {
      this.$store.dispatch('loadEvents');
      this.$store.state.planTxtNone = false;
      this.loading = true;
    }
    const calendarDates = this.$store.state.defaultDate;
    const isRequested = this.$store.state.requested.includes(calendarDates.getTime());
    if (!isRequested || !this.$store.state.loginStatus) return <div />;
    return (
      <div>
        <div class="u-conference">
          <ul ref="panelList" class="c-panel_list">
            <li>
              <TheCalender date={calendarDates} />
            </li>
          </ul>
        </div>
        <div id="js_fixed-btn_wrap">
          <div class="c-reserve-btn__wrap c-visual__fixed u-side__fixed">
            <span style="user-select:none;" class="c-btn is-active c-reserve-btn" onClick={this.show}>
              会議室を予約
            </span>
          </div>
        </div>
      </div>
    );
  },
};
