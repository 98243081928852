import colors from 'data/colors';
import OneColor from './OneColor';

const selectColors = [];
for (let i = 0; i < colors.length; i += 2) {
  selectColors.push([colors[i], colors[i + 1]].filter(Boolean));
}

export default {
  name: 'TheModalColorPickerSelectList',
  props: {
    activeColor: {
      type: Object,
      required: true,
    },
  },
  render() {
    return (
      <modal name="TheModalColorPickerSelectList" width="64px" height="160px" pivotY={0.4} pivotX={0.9} scrollable={false}>
        <div class="c-plan__wrap">
          <div class="c-plan-select__color-box" data-back-to-cancel="false">
            <div class="c-plan-select__color-box__inner">
              <div jscontroller="CoDNrf" data-colors-per-row="2">
                <div>
                  {selectColors.map((colors) => {
                    return (
                      <div class="c-plan-option__wrap">
                        {colors.map((color) => (
                          <OneColor active={this.activeColor === color} color={color} onClick={() => this.$listeners.change(color)} />
                        ))}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
    );
  },
};
