import { hours, minutes } from 'data/times';
import NumberSelect from '../../../../components/NumberSelect';

const options = new Array(20).fill(null).map((n, i) => ({ label: i + 1, value: i + 1 }));

export default {
  name: 'TheSearchByConditions',
  mounted() {
    this.forceOpen = false;
    this.$refs.acContent2.style.height = this.$refs.acContent2.firstElementChild.offsetHeight + 'px';
    this.unwatchTags = this.$store.watch(
      (s) => s.tags,
      () => {
        this.$refs.acContent2.style.height = this.$refs.acContent2.firstElementChild.offsetHeight + 'px';
      }
    );
  },
  destroyed() {
    this.unwatchTags && this.unwatchTags();
  },
  render() {
    const tags = this.$store.state.tags;
    const checked =
      this.$store.state.selectedTags.length ||
      !this.$store.getters.isDefaultConditions ||
      this.$store.getters.hasDragCandidate ||
      (this.$refs.checkbox && this.$refs.checkbox.checked) ||
      this.forceOpen;
    return (
      <div id="js_detail_wrap">
        <div class="p-detail-box-wrap">
          <div class="c-modal__reserve-sub__list ac">
            <input id="ac2" type="checkbox" checked={checked} />
            <label for="ac2" class={['c-conditions__ttl', 'c-modal-ttl', { 'is-active': checked }]} id="labelConditions" onClick={this.$listeners.click}>
              会議室の条件から検索
            </label>
            <div ref="acContent2" class="te" onTransitionstart={(e) => e.target.classList.remove('te')} onTransitionend={(e) => e.target.classList.add('te')}>
              <div class="c-modal__reserve-sub__list-item">
                <p class="c-modal-content__sub-ttl">会議室の条件</p>
                <div class="c-conditions-people__wrap c-adding_fixed-conditions">
                  <div class="c-conditions-people__box">
                    所要時間
                    <NumberSelect
                      class="c-conditions-people"
                      value={this.$store.state.rangeSearchHours}
                      options={hours}
                      onInput={(e) => {
                        this.$set(this, 'forceOpen', true);
                        this.$store.commit('setRangeSearchHours', e);
                      }}
                    />
                    時間
                    <NumberSelect
                      class="c-conditions-people c-adding_fixed-conditions"
                      value={this.$store.state.rangeSearchMinutes}
                      options={minutes}
                      onInput={(e) => {
                        this.$set(this, 'forceOpen', true);
                        this.$store.commit('setRangeSearchMinutes', e);
                      }}
                    />
                    分
                  </div>
                  <div class="c-conditions-people__box">
                    収容人数
                    <NumberSelect
                      class="c-conditions-people"
                      value={this.$store.state.capacity}
                      options={options}
                      onInput={(e) => {
                        this.$set(this, 'forceOpen', true);
                        this.$store.commit('setMaxCapacity', e);
                      }}
                    />
                    人以上
                  </div>
                </div>
                <p class="c-conditions__list-ttl">・検索条件</p>
                <ul class="c-conditions__list">
                  {tags.map((tag) => {
                    const classList = { 'is-active': this.$store.state.selectedTags.includes(tag.id) };
                    return (
                      <li
                        class={classList}
                        onClick={() => {
                          this.$store.commit('toggleSelectedTag', tag.id);
                          this.forceOpen = true;
                        }}
                      >
                        {tag.name}
                      </li>
                    );
                  })}
                </ul>
                <label for="condition01" class="c-conditions__check-label">
                  <input id="condition01" type="checkbox" class="c-conditions__check" onChange={(e) => this.$store.commit('setReversedTag', e.target.checked)} />
                  <span class="c-conditions__check-label">除外して検索</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};
