import Datepicker from 'vuejs-datepicker';
import { ja } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'TheCalendarTopDate',
  props: {
    date: Date,
  },
  data() {
    return {
      enableClick: true,
    };
  },
  methods: {
    handleClick(data) {
      this.enableClick = false;
      let start = new Date(data.currentTarget.dataset.days.replace(/-/g, '/'));
      if (data.currentTarget.dataset.arrow === 'cur') {
        const date = new Date();
        start = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      }
      this.$store.dispatch('loadEvents', { date: start, days: 7 }).then(() => {
        this.$store.commit('updateDefault', start);
        this.enableClick = true;
      });
    },
  },
  render() {
    const openDate = new Date(this.$store.state.requested[0]);
    const now = new Date();
    const defaultDate = this.$store.state.defaultDate;
    const nextDate = new Date(defaultDate);
    const prevDate = new Date(defaultDate);
    nextDate.setDate(nextDate.getDate() + 1);
    prevDate.setDate(prevDate.getDate() - 1);
    const nextDay = `${nextDate.getFullYear()}-${(nextDate.getMonth() + 1).toString().padStart(2, 0)}-${nextDate.getDate()} 00:00:00`;
    const prevDay = `${prevDate.getFullYear()}-${(prevDate.getMonth() + 1).toString().padStart(2, 0)}-${prevDate.getDate()} 00:00:00`;
    const thisDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const isToday = new Date(this.date).getTime() === new Date(thisDate).getTime();
    return (
      <div class="c-side-menu__date-label">
        <div class="c-side-menu__date-label__wrap" style="position: relative">
          <div>
            <Datepicker
              ref="picker"
              class="c-date-select c-side-menu__date c-date-select__txt"
              input-class="datePicker_input"
              language={ja}
              value={this.date}
              format="yyyy年MM月"
              onInput={this.$listeners.input}
              inline={this.open}
            />
            <Datepicker
              ref="picker"
              class={['c-select-day_wrap u-conference c-side-menu__date c-select-day__number', isToday && 'is-today']}
              language={ja}
              value={this.date}
              input-class="datePicker_input"
              format="dd日（D）"
              onInput={this.$listeners.input}
              inline={this.open}
            />
          </div>
          {/* {isToday && <p class="c-select-day__label is-today c-label">Today</p>} */}
          <p class="c-select-day__btn  is-svg u-prev" onClick={this.handleClick} data-arrow="next" data-days={prevDay}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" aria-hidden="true" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </p>

          <p class="c-select-day__btn  is-svg u-next" onClick={this.handleClick} data-arrow="prev" data-days={nextDay}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" aria-hidden="true" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </p>
        </div>
        <p class="c-date-label c-label" onClick={this.handleClick} data-arrow="cur" data-days={openDate}>
          Today
        </p>
      </div>
    );
  },
};
