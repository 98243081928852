export default {
  name: 'MeetSwitch',
  props: {
    meetflg: {
      type: Number,
      default: 0,
    },
    editable: {
      type: Boolean,
      default: () => false,
    },
    meetid: {
      type: String,
      default: '',
    },
    event: {
      type: Object,
      // required: true,
    },
  },
  data() {
    return {
      bval: 'Google Meet のビデオ会議を追加',
      style: 'display:none; border-radius: 50%; width: 20px;',
      btnStyle:
        'background: rgb(66, 133, 244); max-width: 250px; width: 250px; font-weight: bold; font-size: 12px; white-space: normal; box-shadow: rgba(0,0,0,0.1) 0px 3px 1px; border-radius: 5px;',
      bInit: false,
      curMeetflg: -1,
      curMeetid: '',
      curNo: 0,
    };
  },
  computed: {
    setMeetFlg() {
      this.$store.commit('meetflg', this.meetflg);
      return this.meetflg;
    },
  },
  methods: {
    clickMeet() {
      if (this.editable === true) {
        this.$store.commit('meetflg', 1);
        document.getElementById('meetflg_can_' + this.curNo).style.display = 'block';
        document.getElementById('btnMeetTgl').style.background = 'rgb(1, 166, 155);';
        document.getElementById('btnMeetTgl').value = 'Google Meet のビデオ会議追加済み';
        this.bval = 'Google Meetのビデオ会議追加済み';
        this.btnStyle =
          'background: rgb(1, 166, 155); max-width: 250px; width: 250px; font-weight: bold; font-size: 12px; white-space: normal; box-shadow: rgba(0,0,0,0.1) 0px 3px 1px; border-radius: 5px;';
        this.curMeetflg = 1;
      }
    },
    clickMeetCancel() {
      if (this.editable === true) {
        this.$store.commit('meetflg', 0);
        document.getElementById('btnMeetTgl').style.background = 'rgb(66, 133, 244);';
        document.getElementById('meetflg_can_' + this.curNo).style.display = 'none';
        document.getElementById('btnMeetTgl').value = 'Google Meet のビデオ会議を追加';
        this.bval = 'Google Meet のビデオ会議追加';
        this.btnStyle =
          'background: rgb(66, 133, 244); max-width: 250px; width: 250px; font-weight: bold; font-size: 12px; white-space: normal; box-shadow: rgba(0,0,0,0.1) 0px 3px 1px; border-radius: 5px;';
        this.curMeetflg = 0;
      }
    },
    clickCopy() {
      console.log(this.event);
      const url = 'https://meet.google.com/' + this.meetid;
      let str = this.bndDate() + '\n';
      str += 'Google Meet の参加に必要な情報\n';
      str += 'ビデオ通話のリンク: ' + url;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(str);
        const options = {
          position: 'bottom-center',
          duration: 2000,
        };
        this.$toasted.show('会議情報をコピーしました', options);
      }
    },
    bndDate() {
      const strday = new Date(this.event.start_time);
      const endday = new Date(this.event.end_time);
      const mm = strday.getMonth() + 1;
      const dd = strday.getDate();
      const widx = strday.getDay();
      const arrWeek = ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'];
      const ww = arrWeek[widx];
      let h = strday.getHours();
      let hh1 = '';
      if (h > 12) {
        hh1 = '午後' + (h - 12);
      } else {
        hh1 = '午前' + h;
      }
      let m = strday.getMinutes();
      let hmm = '';
      if (m < 10) {
        hmm = '0' + m;
      } else {
        hmm = m;
      }
      hh1 += ':' + hmm;

      h = endday.getHours();
      let hh2 = '';
      if (h > 12) {
        hh2 = '午後' + (h - 12);
      } else {
        hh2 = '午前' + h;
      }
      m = endday.getMinutes();
      hmm = '';
      if (m < 10) {
        hmm = '0' + m;
      } else {
        hmm = m;
      }
      hh2 += ':' + hmm;

      return mm + '月 ' + dd + '日 (' + ww + ') · ' + hh1 + '～' + hh2;
    },
  },
  render() {
    if (this.event !== null) {
      console.log('meetSwitch Start evtid:' + this.event.eventId);
      console.log(this.event);
      this.curNo = this.event.eventId;
    } else {
      console.log('meetSwitch start event null');
    }
    if (this.curMeetflg === -1) {
      this.curMeetflg = this.meetflg;
    }
    const p = this.setMeetFlg;
    let bMFlg = true;
    let bLnk = '';
    if (this.meetid === null || this.meetid === '') {
      bMFlg = false;
    }
    if (bMFlg === true && this.editable === false && this.curMeetflg === 1) {
      this.bval = 'Google Meet の\nビデオ会議に参加する';
      this.style = 'display:none; border-radius: 50%; width: 20px;';
      bLnk = 'https://meet.google.com/' + this.meetid;
    } else {
      if (this.curMeetflg === 1) {
        this.bval = 'Google Meet のビデオ会議追加済み';
        if (document.getElementById('meetflg_can_' + this.curNo) !== null) {
          document.getElementById('meetflg_can_' + this.curNo).style.display = 'block';
        }
        this.style = 'display:block; border-radius: 50%; width: 20px;';
        this.btnStyle =
          'background: rgb(1, 166, 155); max-width: 250px; width: 250px; font-weight: bold; font-size: 12px; white-space: normal; box-shadow: rgba(0,0,0,0.1) 0px 3px 1px; border-radius: 5px;';
      } else {
        if (document.getElementById('meetflg_can_' + this.curNo) !== null) {
          document.getElementById('meetflg_can_' + this.curNo).style.display = 'none';
        }
        this.style = 'display:none; border-radius: 50%; width: 20px;';
        this.bval = 'Google Meet のビデオ会議を追加';
      }
    }
    this.bInit = true;
    const canbtnId = 'meetflg_can_' + this.curNo;
    return (
      <div style="margin-bottom: 10px; width: 100%">
        <div style="display: flex; width: 100%">
          <div style="width: 100%">
            {bLnk !== '' && (
              <div style="display: flex; flex-wrap: nowrap; justify-content: center; align-items: center;">
                <div class="c-plan-textarea__svg">
                  <img
                    alt=""
                    aria-hidden="true"
                    src="https://ssl.gstatic.com/calendar/images/conferenceproviders/logo_meet_2020q4_192px.svg"
                    style="min-width: 20px; width:20px; height:20px;"
                  />
                </div>
                <a
                  href={bLnk}
                  class="c-btn is-active c-reserve-btn"
                  style="max-width: 300px; font-weight: bold; font-size: 12px; white-space:pre-wrap; word-wrap:break-word; line-height: 20px;"
                  target="_blank"
                >
                  {this.bval}
                </a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  style="margin-left: 10px; margin-top: 5px; fill: #fff; margin-right: 10px;"
                  onClick={(e) => this.clickCopy()}
                  v-tooltip="会議情報をコピー"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                </svg>
              </div>
            )}
            {bMFlg === true && bLnk !== '' && <p style="margin-left: 30px; font-size: 12px; color: rgb(255,255,255);">{this.meetid}</p>}
            {bLnk === '' && (
              <div style="display: flex; flex-wrap: nowrap; justify-content: center; align-items: center;">
                <div class="c-plan-textarea__svg">
                  <img
                    alt=""
                    aria-hidden="true"
                    src="https://ssl.gstatic.com/calendar/images/conferenceproviders/logo_meet_2020q4_192px.svg"
                    style="min-width: 20px; width:20px; height:20px;"
                  />
                </div>
                <input type="button" class="c-btn is-active c-reserve-btn" style={this.btnStyle} id="btnMeetTgl" value={this.bval} onClick={(e) => this.clickMeet()} />
                <div style="margin-left: 10px; height: 20px; width:20px; margin-top: 5px;" onClick={() => this.clickMeetCancel()}>
                  <p class="c-select-cancel__btn" id={canbtnId} style={this.style} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
};
