export default {
  name: 'CardTypesTentativeCancel',
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    show() {
      this.$store.state.storeReservationCancel = this.event;
      this.$store.commit('updateModalBtn', 'tentativeCancel');
      this.$modal.show('TheModalMyReserveCheck');
    },
  },
  render() {
    return (
      <p class="c-btn c-myreserve__btn" style="margin: 0 auto;" onClick={this.show}>
        予約をキャンセル
      </p>
    );
  },
};
