export default {
  name: 'CardTypesTentativePlanDetail',
  props: {
    event: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  render() {
    return (
      <div>
        <div class="c-plan-textarea__wrap">
          <div class="c-plan-textarea__svg">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
              <path d="M3 18h12v-2H3v2zM3 6v2h18V6H3zm0 7h18v-2H3v2z" />
              <path d="M0 0h24v24H0V0z" fill="none" />
            </svg>
          </div>
          {!this.editable && (
            <p class="c-plan-textarea" style="color: #666;">
              {this.event.googleCal.description}
            </p>
          )}
          {this.editable && (
            <textarea class="c-plan-textarea" cols="30" rows="10" style="color: #666;">
              {this.event.googleCal.description}
            </textarea>
          )}
        </div>
      </div>
    );
  },
};
