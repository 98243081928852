export default {
  name: 'TransparencySelect',
  props: {
    editable: {
      type: Boolean,
      default: () => false,
    },
    defval: {
      type: String,
      default: 'opaque',
    },
  },
  data() {
    return {
      value: '',
      arr: [
        { label: '予定あり', code: 'opaque' },
        { label: '予定なし', code: 'transparent' },
      ],
    };
  },
  computed: {
    setTransparency() {
      this.$store.commit('transparency', this.transparency);
      return this.transparency;
    },
  },
  methods: {
    selectValue() {
      for (let i = 0; i < this.arr.length; i++) {
        if (this.arr[i].code === this.value) {
          return this.arr[i];
        }
      }
      return this.arr[0];
    },
  },

  render() {
    const handleInputTrans = (selected) => {
      console.log('transparency:' + this.value);
      this.$store.commit('transparency', this.value);
    };
    if (this.value === '') {
      this.value = this.defval;
      console.log('default transparency:' + this.value);
      this.$store.commit('transparency', this.defval);
    }

    const vval = this.selectValue().label;
    const options = [];
    options.push(...this.arr.map((l) => <option value={l.code}>{l.label}</option>));
    if (this.editable === true) {
      return (
        <select class="c-search-new-time" style="width: 100px; height:20px;" vModel={this.value} data-selected={this.value} onChange={handleInputTrans}>
          {options}
        </select>
      );
    } else {
      return <span>{vval}</span>;
    }
    // return <VueSelect options={this.arr} onInput={handleInputVflg} value={this.selectValue()}></VueSelect>;
  },
};
