export default {
  name: 'VisibilitySelect',
  props: {
    editable: {
      type: Boolean,
      default: () => false,
    },
    defval: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      value: '',
      arr: [
        { label: 'デフォルトの公開設定', code: 'default' },
        { label: '公開', code: 'public' },
        { label: '非公開', code: 'private' },
      ],
    };
  },
  computed: {
    setDefval() {
      this.$store.commit('visibility', this.defval);
      return this.defval;
    },
  },

  methods: {
    selectValue() {
      console.log('selectValue:' + this.value);
      for (let i = 0; i < this.arr.length; i++) {
        if (this.arr[i].code === this.value) {
          return this.arr[i];
        }
      }
      return this.arr[0];
    },
  },
  render() {
    const handleInputVflg = (selected) => {
      console.log('visibility:' + this.value);
      this.$store.commit('visibility', this.value);
    };
    if (this.value === '') {
      this.value = this.defval;
      this.$store.commit('visibility', this.defval);
    }

    const vval = this.selectValue().label;
    const options = [];
    options.push(...this.arr.map((l) => <option value={l.code}>{l.label}</option>));
    if (this.editable === true) {
      return (
        <select class="c-search-new-time" style="width: 130px; height:20px;" vModel={this.value} data-selected={this.value} onChange={handleInputVflg}>
          {options}
        </select>
      );
    } else {
      return <span>{vval}</span>;
    }
    // return <VueSelect options={this.arr} onInput={handleInputVflg} value={this.selectValue()} class="visibilityChooser"></VueSelect>;
  },
};
