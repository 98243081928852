const type = {
  loading: { class: 'is-error', value: 'ー' },
  error: { class: 'is-error', value: 'ー' },
  ok: { class: 'is-ok', value: '〇' },
  ng: { class: 'is-no', value: '×' },
};

export default {
  name: 'GuestCandidateBox',
  props: {
    status: {
      type: Array,
      default: () => [],
    },
  },
  render() {
    return (
      <ul class="c-guest-join__candidate">
        {this.status.map((status, index) => {
          return (
            <li>
              <p class="c-guest-join__candidate-label c-label c-candidate">{['A', 'B', 'C'][index]}</p>
              <span class={['c-guest-join__candidate-icon', type[status].class]}>{type[status].value}</span>
            </li>
          );
        })}
      </ul>
    );
  },
};
