export default {
  name: 'TheTabs',
  methods: {
    moveTabContent(e) {
      if (!this.$store.state.loginStatus || this.$route.name === e.target.dataset.routeName) return;

      this.$router.push({ path: e.target.dataset.routeName }).catch((e) => {
        console.warn('tab move error', e);
      });
      const selectedElement = this.$el.querySelector('.is-selected');
      selectedElement && selectedElement.classList.remove('is-selected');
      e.target.classList.add('is-selected');
    },
  },
  render() {
    const isRoomReservation = this.$route.name !== 'myReserve';
    return (
      <div>
        <ul ref="tabs" class="c-tab_list" style={{ display: this.$store.state.loginStatus ? '' : 'none' }}>
          <li>
            <p class={['alpha c-tab_link', { 'is-selected': isRoomReservation }]} id="pathToRoomReservation" data-route-name="roomReservation" onClick={this.moveTabContent}>
              会議室予約
            </p>
          </li>
          <li>
            <p class={['alpha c-tab_link', { 'is-selected': !isRoomReservation }]} id="pathToMyReserve" data-route-name="myReserve" onClick={this.moveTabContent}>
              マイリザーブ
            </p>
          </li>
        </ul>
      </div>
    );
  },
};
