import CardCommonGuests from './CardCommonGuests';
import CardCommonPlanDetails from './CardCommonPlanDetails';
import MeetSwitch from '../../../roomReservation/reservation/candidate/meetSwitch';
import VisibilitySelect from '../../../roomReservation/reservation/candidate/visibilitySelect';
import TransparencySelect from '../../../roomReservation/reservation/candidate/transparencySelect';

export default {
  name: 'CardCommonRoom',
  props: {
    event: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  render() {
    const ac = `ac${this.index}`;
    console.log('*********** CardCommonRoom');
    console.log(this.event);
    return (
      <div>
        <div class=" c-myreserve-join__info">
          <div class="ac">
            <input id={!!this.index && ac} type="checkbox" />
            <label for={!!this.index && ac} class="c-myreserve-join__label" id="labelGuest">
              詳細を表示
              <p class="c-myreserve-room__box-svg">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                  <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
              </p>
            </label>
            <div class="te c-myreserve-join__info-box" onTransitionstart={(e) => e.target.classList.remove('te')} onTransitionend={(e) => e.target.classList.add('te')}>
              <CardCommonGuests event={this.event} editable={this.editable} />
              <CardCommonPlanDetails event={this.event} editable={this.editable} />
              <div class="c-plan-textarea__wrap plan-textarea_focus" style="display: flex; flex-wrap: nowrap; justify-content: center; align-items: center;">
                <MeetSwitch editable={this.editable} meetflg={this.event.meetflg} meetid={this.event.meetid} event={this.event} />
              </div>
              <div class="c-plan-textarea__wrap" style="margin-bottom: 32px;">
                <div class="c-plan-textarea__svg">
                  <svg focusable="false" width="20" height="20" viewBox="0 0 24 24" class=" NMm5M" style="margin-top: 5px;">
                    <path d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zM10 4h4v2h-4V4zm10 15H4V8h16v11z"></path>
                  </svg>
                </div>
                {this.editable && (
                  <div class="c-search-new-select__box selbox_cc_edit" style="width: 130px;">
                    <TransparencySelect editable={this.editable} defval={this.event.transparency} />
                  </div>
                )}
                {!this.editable && (
                  <div class="c-search-new-select__box selbox_cc" style="width: 130px;">
                    <TransparencySelect editable={this.editable} defval={this.event.transparency} />
                  </div>
                )}
                <span class="c-search-new-select-while" style="padding: 0 5px;">
                  {' '}
                </span>
                {this.editable && (
                  <div class="c-search-new-select__box selbox_cc_edit" style="width: 200px;">
                    <VisibilitySelect editable={this.editable} defval={this.event.visibility} />
                  </div>
                )}
                {!this.editable && (
                  <div class="c-search-new-select__box selbox_cc" style="width: 200px;">
                    <VisibilitySelect editable={this.editable} defval={this.event.visibility} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};
