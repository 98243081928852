import times from 'data/times';
import getSelectValue from 'functions/time/getSelectValue';
import getInitSearchTime from 'functions/time/getInitSearchTime';

export default {
  name: 'TimeSelect',
  props: {
    value: {
      type: [Date, String],
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    filter: {
      type: Function,
      required: false,
      default: null,
    },
    date: {
      type: Date,
      default: undefined,
    },
  },
  data() {
    return {
      selected: '',
    };
  },
  watch: {
    value() {
      this.selected = getSelectValue(this.value).value;
    },
  },
  mounted() {
    this.selected = getSelectValue(this.value).value;
  },
  beforeUpdate() {
    this.selected = getSelectValue(this.value).value;
  },
  methods: {
    handleTouchEnd() {
      if (this.$el.value === '') {
        this.$el.value = getInitSearchTime().value;
      }
    },
  },
  render() {
    const options = [];
    if (this.placeholder) {
      options.push(<option value="">{this.placeholder}</option>);
    }
    const base = this.filter ? this.filter(times) : times;
    options.push(...base.map((l) => <option value={l.value}>{l.label}</option>));

    return (
      <select
        vModel={this.selected}
        data-selected={this.selected}
        onInput={(e) => {
          const current = times.find((n) => n.value === e.target.value) || { label: '', value: '' };
          this.$listeners.input(current);
        }}
        onTouchend={this.handleTouchEnd}
      >
        {options}
      </select>
    );
  },
};
