import TheSearchByTimesSelectList from './TheSearchByTimesSelectList';

export default {
  name: 'TheSearchByTimes',
  computed: {
    isOpen() {
      if (this.$refs.ac3) return this.$refs.ac3.checked;
      return this.$store.state.editSearchDate || this.$store.state.editSearchTime;
    },
  },
  mounted() {
    this.$refs.acContent3.style.height = this.$refs.acContent3.firstElementChild.offsetHeight + 'px';
  },
  render() {
    return (
      <div id="js_check_wrap" class="p-check-box-wrap">
        <div class="c-modal__reserve-sub__list ac">
          <input ref="ac3" id="ac3" type="checkbox" checked={this.isOpen} />
          <label for="ac3" class={['c-search-room__ttl', 'c-modal-ttl', { 'is-active': this.isOpen }]} id="labelSearchParams" onClick={this.$listeners.click}>
            時間から検索
          </label>
          <div ref="acContent3" class="te" onTransitionstart={(e) => e.target.classList.remove('te')} onTransitionend={(e) => e.target.classList.add('te')}>
            <div class="c-modal__reserve-sub__list-item">
              <p class="c-modal-content__sub-ttl">検索したい時間を入力してください</p>
              <div class="p-search-new__box-wrap">
                <TheSearchByTimesSelectList />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};
