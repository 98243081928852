/**
 * 0埋め
 * @param {int|string} target
 * @param {number|boolean} padding 数値またはfalse
 * @returns {string}
 */
const padStart = (target, padding) => (padding ? target.toString().padStart(padding, '0') : target.toString());

/**
 * Dateオブジェクトをパース
 * @param {Date} date
 * @param {number|boolean} padding 数値またはfalse
 * @returns {{date: string, hours: string, seconds: string, month: string, year: string, minutes: string}}
 */
export default function (date, padding = 2) {
  return {
    year: date.getFullYear().toString(),
    month: padStart(date.getMonth() + 1, padding),
    date: padStart(date.getDate(), padding),
    hours: padStart(date.getHours(), padding),
    minutes: padStart(date.getMinutes(), padding),
    seconds: padStart(date.getSeconds(), padding),
  };
}
