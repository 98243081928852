import store from '../store';
import { getResourceCalenderDetail } from '../network/google/calender';
import formatDate from '../utils/formatDate';

const cancelFinder = (target, cancel) => {
  if (target.length !== cancel.length) {
    store.state.storeReservationEvents = [];
    target.forEach((newEv) => {
      cancel.forEach((schedule) => {
        if (schedule.calendar_id === newEv.calendar_id) {
          store.state.storeReservationEvents.push(newEv);
        }
      });
    });
  }
};

const reMake = (gCal, room, event, mySch) => {
  const guest = !gCal.attendees ? [] : gCal.attendees.filter((email) => email.email !== gCal.organizer.email);
  return {
    googleCal: {
      title: gCal.summary,
      description: gCal.description,
      color: gCal.colorId,
      guest: guest,
      creator: gCal.creator.email,
    },
    mySch,
    resource_id: room.resource_id,
    eventId: event.id,
    calendar_id: event.calendar_id,
    start_time: formatDate(new Date(event.start_time.replace(/-/g, '/'))),
    end_time: formatDate(new Date(event.end_time.replace(/-/g, '/'))),
    room_id: event.room_id,
    location: room.name,
    status: event.status,
    visibility: event.visibility,
    transparency: event.transparency,
    meetflg: event.meetflg,
    meetid: event.meetid,
  };
};

export const findCalenderEvents = async (type, mySchedule) => {
  const storeReserve = store.state.storeReservationEvents;
  const whichSch = mySchedule.map((sch) => ({ ...sch.schedule.events[0], members: sch.schedule.members, sch }));
  if (!whichSch.length) return;

  const promiseReserve = async (resourceId, eventId, room, event, sch) => {
    const resourceCal = await getResourceCalenderDetail({ calendarId: store.state.email, eventId: eventId });
    if (!resourceCal.success) return;
    const calender = resourceCal.calender.result;
    return reMake(calender, room, event, sch);
  };
  whichSch.map(async (ev) => {
    const room = store.state.rooms.find((_this) => _this.id === Number(ev.room_id));
    if (!room) return;
    const event = await promiseReserve(room.resource_id, ev.calendar_id, room, ev, ev.sch);
    const index = storeReserve.findIndex((r) => r.calendar_id === ev.calendar_id);
    if (event && event.status !== '使用中') {
      if (index === -1) {
        store.state.storeReservationEvents.push(event);
      } else {
        store.state.storeReservationEvents[index] = event;
      }
    }
    if (type === 'inUsed') store.state.storeInusedEvents = event;
  });
  if (type === 'reserve' && storeReserve.length) cancelFinder(storeReserve, whichSch);
};
