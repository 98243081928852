import './style.css';
import './sass/common.scss';
import 'animate.css/source/_base.css';
import 'animate.css/source/fading_exits/fadeOutLeft.css';
import './registration';
import Vue from 'vue';
import Toasted from 'vue-toasted';
import VTooltip from 'v-tooltip';
import { VueRouter, router } from './router';
import TheRoot from './views/TheRoot';
import store from './store';
import VModal from 'vue-js-modal';
import { init } from './network/init';

Vue.use(VModal);
Vue.use(VueRouter);
Vue.use(Toasted);
Vue.use(VTooltip);

Vue.config.productionTip = false;
/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  render: (h) => h(TheRoot),
});
init();
