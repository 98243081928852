export const insertGcalender = async (calendarId, guests, params) => {
  if (!gapi.client.calendar) {
    await gapi.client.load('https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest');
  }

  const attendees = guests.map((email) => (email.match(/.+@.+\..+/) !== null ? { email: email.replace(/\r?\n/g, '') } : []));
  const res = {
    ...params,
    attendees: [...attendees],
    start: {
      dateTime: new Date(params.start).toISOString(),
    },
    end: {
      dateTime: new Date(params.end).toISOString(),
    },
  };
  res.visibility = params.vflg;

  if (params.meetflg === 1) {
    res.conferenceData = {
      createRequest: {
        requestId: '200128-02',
        conferenceSolutionKey: {
          type: 'hangoutsMeet',
        },
      },
    };
  }
  return gapi.client.calendar.events.insert({
    calendarId: calendarId,
    conferenceDataVersion: 1,
    resource: res,
  });
};

export const updateGcalender = async (getData) => {
  const data = getData;
  const attendees = data.guests.map((email) => (email.match(/.+@.+\..+/) !== null ? { email: email.replace(/\r?\n/g, '') } : []));
  return gapi.client.calendar.events.update({
    calendarId: data.calenderId,
    eventId: data.eventId,
    resource: {
      start: {
        dateTime: new Date(data.startTime || data.start).toISOString(),
      },
      end: {
        dateTime: new Date(data.endTime || data.end).toISOString(),
      },
      attendees: [...attendees],
      summary: data.title,
      colorId: data.color,
      description: data.detail,
      location: data.location,
    },
  });
};

export const moveCalendarEvent = async (calendarId, eventId, destination) => {
  // eslint-disable-next-line
  return await gapi.client.calendar.events.move({
    calendarId,
    eventId,
    destination,
  });
};

export const getCalendarEventList = async (params) => {
  try {
    if (!gapi.client.calendar) {
      await gapi.client.load('https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest');
    }
    return await gapi.client.calendar.events.list(params);
  } catch (err) {
    return {
      success: false,
      message: err,
      error: 'unauthorized',
    };
  }
};

export const getCalendarEventListAll = (params, items = []) => {
  return getCalendarEventList(params).then((res) => {
    if (res.error) {
      return Promise.reject(res);
    }

    items.push(...res.result.items);

    if (res.result.nextPageToken) {
      const nextParams = { ...params, pageToken: res.nextPageToken };
      params.syncToken && delete params.syncToken;
      return getCalendarEventListAll(nextParams, items);
    }

    return {
      nextSyncToken: res.result.nextSyncToken,
      items,
    };
  });
};

export const getResourceCalenderDetail = async ({ calendarId, eventId }) => {
  try {
    if (!gapi.client.calendar) {
      await gapi.client.load('https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest');
    }
    const calender = await gapi.client.calendar.events.get({
      calendarId: calendarId,
      eventId: eventId,
    });
    return {
      success: true,
      calender,
    };
  } catch (err) {
    return {
      success: false,
      message: err,
      error: 'unauthorized',
    };
  }
};

export const getHoliday = () => {
  const params = { calendarId: 'ja.japanese#holiday@group.v.calendar.google.com', maxResults: 2500 };
  return getCalendarEventListAll(params).then((r) => r.items);
};

export const calenderDelete = async ({ calendarId, eventId }) => {
  try {
    if (!gapi.client.calendar) {
      await gapi.client.load('https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest');
    }
    const calender = await gapi.client.calendar.events.delete({
      calendarId: calendarId,
      eventId: eventId,
    });
    return {
      success: true,
      calender,
    };
  } catch (err) {
    return {
      success: false,
      message: err,
      error: 'unauthorized',
    };
  }
};
