import CardCommonEventSummary from './CardCommonEventSummary';
import CardCommonEventTitle from './CardCommonEventTitle';
import CardCommonDetailTime from '../CardCommonDetailTime';

export default {
  name: 'CardCommonEvent',
  props: {
    event: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  render() {
    const reMake = { ev: this.event };
    return (
      <div>
        <ul class="c-myreserve-list c-modal-list">
          <li class="u-ttl01">
            <CardCommonEventSummary event={this.event} editable={this.editable} />
            <CardCommonEventTitle event={this.event} />
            <CardCommonDetailTime event={reMake} editable={this.editable} type={this.type} />
          </li>
        </ul>
      </div>
    );
  },
};
