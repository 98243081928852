import CalendarEvent from 'classes/CalendarEvent';
import GuestCandidateBox from 'components/guest/GuestCandidateBox';

export default {
  name: 'TheSearchByGuestsSelectedGuestList',
  props: {
    calendarEvent: CalendarEvent,
  },
  data() {
    return {
      status: [],
    };
  },
  watch: {
    email() {
      this.check();
    },
  },
  created() {
    this.check(true).then(() => {
      this.$store.state.candidateRooms.forEach((candidate, index) => {
        if (candidate && !candidate.selected && !this.calendarEvent.isEnable(candidate.start, candidate.end)) {
          this.$set(this.$store.state.candidateRooms, index, null);
        }
      });
    });
  },
  mounted() {
    this.$store.watch(
      (s) => s.candidateRooms,
      () => this.check()
    );
  },
  methods: {
    /**
     * リクエストを送ってチェック
     * @param {Object} room
     * @returns {Promise<boolean>}
     */
    async has(room) {
      const { has } = await this.calendarEvent.hasSchedule(room.start, room.end);
      return has;
    },
    /**
     * ok, ng check
     * @param {boolean} created
     * @returns {Promise<any>}
     */
    check(created = false) {
      this.$store.state.candidateRooms.forEach((room, index) => {
        this.status[index] = 'loading';
        !created && this.$forceUpdate();
      });
      return this.calendarEvent
        .async()
        .catch((e) => Promise.resolve(e))
        .finally(() => {
          this.$store.state.candidateRooms.forEach((room, index) => {
            if (!room) return;
            if (this.calendarEvent.error) {
              this.status[index] = 'error';
              return this.$refs.candidateBox?.$forceUpdate();
            }
            const enable = this.calendarEvent.isEnable(room.start, room.end);
            if (!enable) {
              this.status[index] = 'ng';
              return this.$refs.candidateBox?.$forceUpdate();
            }
            this.has(room).then((t) => {
              this.status[index] = t ? 'ng' : 'ok';
              this.$refs.candidateBox?.$forceUpdate();
            });
          });
        });
    },
  },
  render() {
    return (
      <li data-email={this.calendarEvent.calendarId} data-color={this.calendarEvent.color.color} data-index={this.index}>
        <div class="c-guest-join__info-wrap">
          <p class="c-guest-join__color" style={{ background: this.calendarEvent.color.color }} />
          <div class="c-guest__tooltips c-label-tooltips-item">
            <span class="c-label-tooltips selected-area">{this.calendarEvent.name}</span>
            <p class="c-guest-join__name" onClick={(e) => e.target.offsetWidth !== e.target.scrollWidth && e.target.parentNode.classList.toggle('is-active')}>
              {this.calendarEvent.name}
            </p>
          </div>
        </div>
        <div class="c-guest__tooltips c-guest__Rset-tooltips c-label-tooltips-item">
          <span class="c-label-tooltips selected-area">{this.calendarEvent.calendarId}</span>
          <p class="c-guest-join_email" onClick={(e) => e.target.offsetWidth !== e.target.scrollWidth && e.target.parentNode.classList.toggle('is-active')}>
            {this.calendarEvent.calendarId}
          </p>
        </div>
        <GuestCandidateBox ref="candidateBox" status={this.status} />
        <p class="c-guest-cancel__btn" onClick={() => this.$store.commit('removeGuest', this.calendarEvent)} />
      </li>
    );
  },
};
