export default {
  name: 'CalendarRoomSpace',
  props: {
    start: {
      type: Date,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    end() {
      const end = new Date(this.start);
      end.setHours(end.getHours() + 1);
      return end;
    },
  },
  render() {
    const h1 = this.start.getHours();
    const m1 = this.start.getMinutes();
    const h2 = m1 >= 30 ? h1 + 1 : h1;
    const m2 = m1 >= 30 ? (m1 + 30) % 60 : m1 + 30;

    return (
      <div class="c-calendar__table-td c-calendar__table-sub__tooltips c-label-tooltips-item">
        <span class="c-label-tooltips" data-start-hours={h1} data-start-minutes={m1} data-end-hours={h2} data-end-minutes={m2}>
          {`${h1}:${m1.toString().padStart(2, '0')}〜${h2}:${m2.toString().padStart(2, '0')}`}
        </span>
      </div>
    );
  },
};
