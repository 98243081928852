import CardTypesTentativeEvents from './events/CardTypesTentativeEvents';
import CardTypesTentativeGuests from './CardTypesTentativeGuests';
import CardTypesTentativePlanDetail from './CardTypesTentativePlanDetail';
import MeetSwitch from '../../../../roomReservation/reservation/candidate/meetSwitch';
import VisibilitySelect from '../../../../roomReservation/reservation/candidate/visibilitySelect';
import TransparencySelect from '../../../../roomReservation/reservation/candidate/transparencySelect';

const labelStyle = {
  color: '#666',
  textAlign: 'right',
  padding: '5px 25px 15px 0',
  position: 'relative',
};
const svgStyle = {
  position: 'absolute',
  right: '0',
  top: '-4px',
};

export default {
  name: 'CardTypesTentativeDetail',
  props: {
    event: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isOwner: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    changes() {
      this.icon = !this.icon;
    },
  },
  render() {
    const num = this.icon ? 'rotate(180deg)' : 'none';
    const iconOn = {
      transform: num,
    };
    return (
      <div class="c-myreserve-join__output ac">
        <div class="c-myreserve-candidate" style="top: 77px; z-index: 200;">
          {!this.editable && <p class="c-myreserve-candidate-ttl c-label c-candidate">{this.event.googleCal.title}</p>}
          {this.editable && <input type="text" style="" class="c-myreserve-candidate-ttl c-label c-candidate" value={this.event.googleCal.title} />}
        </div>
        <input id={['ac10' + this.index + 1]} type="checkbox" />
        <label for={['ac10' + this.index + 1]} class="" onClick={this.$listeners.click}>
          <div onClick={this.changes} style={labelStyle}>
            詳細を表示
            <p class="c-myreserve-room__box-svg" style={svgStyle}>
              <svg style={iconOn} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
            </p>
          </div>
        </label>
        <div class="c-myreserve__sub a1c-select-room__box te" ref="acContent1">
          <ul class="c-myreserve-list c-modal-list">
            <li class="u-ttl01">
              <CardTypesTentativeEvents event={this.event} editable={this.editable} />
            </li>
          </ul>
          <CardTypesTentativeGuests event={this.event} editable={this.editable} />
          <CardTypesTentativePlanDetail event={this.event} editable={this.editable} />
          <div class="c-plan-textarea__wrap plan-textarea_focus">
            <MeetSwitch editable={this.editable} meetflg={this.event.meetflg} meetid={this.event.meetid} event={this.event} />
          </div>
          <div class="c-plan-textarea__wrap" style="margin-bottom: 32px;">
            {this.editable && (
              <div class="c-search-new-select__box selbox_dd_edit" style="width: 130px;">
                <TransparencySelect editable={this.editable} defval={this.event.transparency} />
              </div>
            )}
            {!this.editable && (
              <div class="c-search-new-select__box selbox_dd" style="width: 130px;">
                <TransparencySelect editable={this.editable} defval={this.event.transparency} />
              </div>
            )}
            <span class="c-search-new-select-while"> </span>
            {this.editable && (
              <div class="c-search-new-select__box selbox_dd_edit" style="width: 200px;">
                <VisibilitySelect editable={this.editable} defval={this.event.visibility} />
              </div>
            )}
            {!this.editable && (
              <div class="c-search-new-select__box selbox_dd" style="width: 200px;">
                <VisibilitySelect editable={this.editable} defval={this.event.visibility} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
};
