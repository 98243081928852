import store from '../store';
import { getResourceCalenderDetail } from '../network/google/calender';

const reMake = (gCal, event, eventId) => {
  const guest = !gCal.attendees ? [] : gCal.attendees.filter((email) => email.email !== gCal.organizer.email);
  return {
    googleCal: {
      title: gCal.summary,
      description: gCal.description,
      color: gCal.colorId,
      guest: guest,
      creator: gCal.creator.email,
    },
    mySch: event,
    calendar_id: eventId,
    status: '仮予約',
  };
};

const cancelFinder = (target, cancel) => {
  if (target.length !== cancel.length) {
    store.state.storeTentativeEvents = [];
    target.forEach((newEv) => {
      cancel.forEach((ev) => {
        if (ev.schedule.events[0].calendar_id === newEv.calendar_id) {
          store.state.storeTentativeEvents.push(newEv);
        }
      });
    });
  }
};

export const findCalenderTentativeEv = (mySchedule) => {
  const promiseReserve = async (resourceId, eventId, event) => {
    const resourceCal = await getResourceCalenderDetail({ calendarId: resourceId, eventId: eventId });
    if (!resourceCal.success) return;
    const calender = resourceCal.calender.result;
    return reMake(calender, event, eventId);
  };

  mySchedule.forEach(async (sch) => {
    const targetEvent = sch.schedule.events[0];
    const calenderId = targetEvent.calendar_id;
    const index = store.state.storeTentativeEvents.findIndex((n) => n.calendar_id === calenderId);
    const room = store.state.rooms.find((room) => room.id === Number(targetEvent.room_id));
    const event = await promiseReserve(room.resource_id, calenderId, sch);

    if (event) {
      if (index === -1) {
        store.state.storeTentativeEvents.push(event);
      } else {
        store.state.storeTentativeEvents[index] = event;
      }
    }
  });
  if (store.state.storeTentativeEvents.length) cancelFinder(store.state.storeTentativeEvents, mySchedule);
};
