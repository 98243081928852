/**
 * 配列をランダムに並び替える
 * @param {array} arr
 * @returns {array}
 */
export default function (arr) {
  const newArr = [...arr];
  for (let i = newArr.length - 1; i > 0; i--) {
    const r = Math.floor(Math.random() * (i + 1));
    const tmp = newArr[i];
    newArr[i] = newArr[r];
    newArr[r] = tmp;
  }
  return newArr;
}
