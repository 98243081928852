import VueRouter from 'vue-router';
import TheLogin from 'views/login/TheLogin';
import roomReservation from 'views/roomReservation/TheRoomReservation';
import TheMyReserve from 'views/myReserve/TheMyReserve';

const routes = [
  { path: '/login', name: 'login', component: TheLogin },
  { path: '/roomReservation', name: 'roomReservation', component: roomReservation },
  { path: '/myReserve', name: 'myReserve', component: TheMyReserve },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export { router, VueRouter };
