import colors, { findColorById } from 'data/colors';
import TheModalColorPickerSelectList from './TheModalColorPickerSelectList';

const selectLabels = [];
for (let i = 0; i < colors.length; i += 2) {
  selectLabels.push([colors[i], colors[i + 1]].filter(Boolean));
}

export default {
  name: 'TheColorPicker',
  props: {
    initialColorId: {
      type: Number,
      default: 11,
    },
  },
  data() {
    return {
      color: findColorById(this.initialColorId),
    };
  },
  methods: {
    show() {
      this.$modal.show('TheModalColorPickerSelectList');
      this.$store.commit('modalControl', true);
    },
    hide() {
      this.$refs.bg.style.display = 'none';
      this.$modal.hide('TheModalColorPickerSelectList');
    },
    handleChange(color) {
      this.color = color;
      this.$store.commit('color', { id: color.colorId, code: color.color });
      this.hide();
      this.$listeners.change(color);
    },
  },
  render() {
    return (
      <div class="c-plan-select__color-wrap" id="js_color_label_list">
        <div slot="top-right" class="c-plan-select__color-top">
          <button ref="bg" id="js_v_modal_label_list" onClick={this.hide} />
        </div>
        <div class="c-plan-select__color" onClick={this.show}>
          <div class="c-plan-option" style={{ background: this.color.color }} data-color={this.color.color} data-colorid={this.color.id} />
        </div>
        <TheModalColorPickerSelectList activeColor={this.color} onChange={this.handleChange} />
      </div>
    );
  },
};
