import CalendarRoomSpace from './CalendarRoomSpace';
import CalendarSelectedRoomSpace from './CalendarSelectedRoomSpace';
import CalendarEventSpace from './CalendarEventSpace';
import { dragscroll } from 'vue-dragscroll';
import dragging from 'states/dragging';
import convertStringTime from 'utils/convertStringTime';

const getTips = (target) => {
  if (!target) return;
  if (target.classList.contains('c-label-tooltips')) {
    return target;
  } else if (target.tagName === 'P') {
    return target.parentNode.querySelector('.c-label-tooltips');
  }
  return target.querySelector('.c-label-tooltips');
};

export default {
  name: 'TheCalendarRooms',
  directives: {
    dragscroll: dragscroll,
  },
  props: {
    date: {
      type: Date,
      required: true,
    },
    rooms: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      canRender: true,
    };
  },
  mounted() {
    this.$store.watch(
      (s) => s.options,
      () => this.$forceUpdate()
    );
  },
  methods: {
    handleRequestAnimationFrame() {
      this.canRender = true;
    },
  },
  render() {
    const pos = { left: 0, x: 0, y: 0, start: 0 };

    const handleTouchStart = (e, room) => {
      if (e.targetTouches.length !== 1) return;
      pos.left = this.$refs.spaces.scrollLeft;
      this.e = e;
      pos.x = Number(e.targetTouches[0].clientX);
      pos.y = Number(e.targetTouches[0].clientY);
      pos.start = pos.x;
      const tips = getTips(e.target);
      if (!tips) return;
      dragging.reset(dragging.timer);
      dragging.Y = e.touches[0].screenY;
      dragging.tips = tips;
      dragging.timer = setTimeout(() => {
        const start = [+tips.dataset.startHours, +tips.dataset.startMinutes];
        const end = [+tips.dataset.endHours, +tips.dataset.endMinutes];
        const startDate = new Date(this.date);
        startDate.setHours(start[0]);
        startDate.setMinutes(start[1]);
        const endDate = new Date(this.date);
        if (start[0] === 23 && end[0] === 0) {
          endDate.setDate(endDate.getDate() + 1);
          endDate.setHours(0);
          endDate.setMinutes(0);
        } else {
          endDate.setHours(end[0]);
          endDate.setMinutes(end[1]);
        }
        if (this.$store.getters.hasEvent(room.id, startDate, endDate)) return;
        const candidate = this.$store.state.candidateRooms.find((r) => {
          if (!r || !r.selected || r.id !== room.id) return false;
          return startDate >= r.start && startDate < r.end && endDate <= r.end && endDate > r.start;
        });
        if (candidate) {
          dragging.candidate = candidate;
          dragging.hasSearch = this.$store.getters.findSearch(candidate.start, candidate.end);
          if (!this.$store.state.editSearchTime) {
            dragging.hasSearch = true;
            this.$store.state.searchTimeStart = convertStringTime(candidate.start);
            this.$store.state.searchTimeEnd = convertStringTime(candidate.end, true);
            this.$store.state.editSearchTime = true;
          }
          dragging.status = startDate.getTime() === candidate.start.getTime() || endDate.getTime() === candidate.end.getTime() ? 'exist' : 'deletable';
        } else if (this.$store.getters.isEmptyCandidate) {
          const cs = this.$store.getters.addCandidateSearch({
            id: room.id,
            start: startDate,
            end: endDate,
            selected: true,
          });
          dragging.candidate = cs.candidate;
          dragging.hasSearch = this.$store.getters.findSearch(dragging.candidate.start, dragging.candidate.end);
          if (!this.$store.state.editSearchTime) {
            dragging.hasSearch = true;
            this.$store.state.searchTimeStart = convertStringTime(dragging.candidate.start);
            this.$store.state.searchTimeEnd = convertStringTime(dragging.candidate.end, true);
            this.$store.state.editSearchTime = true;
          }
          dragging.status = 'new';
        } else {
          return;
        }
        dragging.times = { start, startDate, end, endDate };
        dragging.moves = { start, end };
        dragging.target = room.id;
        dragging.isMoved = true;
        navigator.vibrate && navigator.vibrate(100);
      }, 500);
    };

    const handleTouchMove = (e, room) => {
      if (e.targetTouches.length !== 1 || dragging.isScrolled) return;
      if (!dragging.isMoved && dragging.scrollMode !== 'vertical') {
        const dx = e.targetTouches[0].clientX - pos.x;
        const dy = e.targetTouches[0].clientY - pos.y;

        if (dragging.scrollMode === null && Math.abs(dx) < Math.abs(dy)) {
          dragging.scrollMode = 'vertical';
          return;
        }

        const amount = pos.left - dx;
        if (this.$refs.spaces.scrollLeft === amount) return;
        dragging.scrollMode = 'horizontal';
        this.$refs.spaces.scrollLeft = amount;
        pos.left = amount;
        pos.x = Number(e.targetTouches[0].clientX);
        if (dragging.timer && Math.abs(pos.start - pos.x) > 10) {
          clearTimeout(dragging.timer);
        }
        this.$listeners.scroll({ target: this.$refs.spaces });
        return;
      }
      const tips = getTips(document.elementFromPoint(e.touches[0].pageX, e.touches[0].pageY));
      if (!tips) return;
      if (!dragging.isMoved && (dragging.tips !== tips || Math.abs(dragging.Y - e.touches[0].screenY) > 30)) {
        return dragging.reset();
      }
      if (dragging.target !== room.id || !dragging.times) return;
      const start = [+tips.dataset.startHours, +tips.dataset.startMinutes];
      const end = [+tips.dataset.endHours, +tips.dataset.endMinutes];
      if (start[0] === dragging.moves.start[0] && start[1] === dragging.moves.start[1] && end[0] === dragging.moves.end[0] && end[1] === dragging.moves.end[1]) {
        return;
      }
      const moveStart = new Date(this.date);
      moveStart.setHours(start[0]);
      moveStart.setMinutes(start[1]);
      const moveEnd = new Date(this.date);
      moveEnd.setHours(end[0]);
      moveEnd.setMinutes(end[1]);
      if (start[0] === 23 && end[0] === 0) {
        moveEnd.setDate(moveEnd.getDate() + 1);
        moveEnd.setHours(0);
        moveEnd.setMinutes(0);
      }
      if (this.$store.getters.hasEvent(room.id, moveStart, moveEnd)) return;
      const candidate = dragging.candidate;

      const ds = dragging.times.startDate < moveStart ? dragging.times.startDate : moveStart;
      const de = dragging.times.endDate > moveEnd ? dragging.times.endDate : moveEnd;

      if (this.$store.getters.hasEvent(dragging.target, ds, de)) return;

      dragging.moves.start = start;
      dragging.moves.end = end;

      if (dragging.status === 'deletable') return;

      if (dragging.status === 'exist') {
        const setDate = (name, [hour, min]) => {
          const date = new Date(dragging.candidate[name]);
          date.setHours(hour);
          date.setMinutes(min);
          const s = name === 'start' ? date : dragging.candidate.start;
          const e = name === 'end' ? date : dragging.candidate.end;
          if (e < s) return;
          this.$set(dragging.candidate, name, date);
        };
        const halfHours = 1800000;
        if (moveStart >= candidate.start && moveStart < candidate.end && moveEnd <= candidate.end && moveEnd > candidate.start) {
          if (moveStart.getTime() - halfHours === candidate.start.getTime()) {
            setDate('start', start);
          } else {
            setDate('end', end);
          }
        } else {
          if (moveStart >= candidate.start && moveStart < candidate.end) {
            setDate('end', end);
          } else if (moveStart.getTime() === candidate.end.getTime()) {
            setDate('end', end);
          } else {
            setDate('start', start);
          }
        }
      } else if (candidate) {
        candidate.start = ds;
        candidate.end = de;
      }
    };

    const handleTouchEnd = () => {
      if (dragging.isScrolled && dragging.isMoved) return;
      if (!dragging.candidate) return;
      const { start, end } = dragging.candidate;
      const hasSearch = dragging.hasSearch;
      if (hasSearch) {
        const params = { start: new Date(start), end: new Date(end) };
        if (params.start < new Date()) {
          this.$store.commit('clearSearchTime');
        }
        this.$store.state.searchTimeStart = convertStringTime(start);
        this.$store.state.searchTimeEnd = convertStringTime(end);
      }
      if (start.getTime() === end.getTime()) {
        if (hasSearch) {
          this.$store.commit('removeSearchTime', [start, end]);
        }
        return this.$store.commit('updateCandidate', { candidate: null, target: dragging.candidate });
      }
      if (!(dragging.times.start === dragging.moves.start && dragging.times.end === dragging.moves.end)) return;
      if (dragging.status === 'deletable') {
        if (hasSearch) this.$store.commit('clearSearchTime');
        this.$store.commit('updateCandidate', { candidate: null, target: dragging.candidate });
      } else if (dragging.status === 'exist') {
        if (dragging.times.startDate.getTime() === dragging.candidate.start.getTime() && dragging.times.endDate.getTime() === dragging.candidate.end.getTime()) {
          if (hasSearch) this.$store.commit('removeSearchTime', [dragging.candidate.start, dragging.candidate.end]);
          this.$store.commit('updateCandidate', { candidate: null, target: dragging.candidate });
        } else if (dragging.times.startDate.getTime() === dragging.candidate.start.getTime()) {
          const date = new Date(dragging.candidate.start);
          date.setMinutes(date.getMinutes() + 30);
          dragging.candidate.start = date;
          if (hasSearch) {
            this.$store.state.searchTimeStart = convertStringTime(date);
          }
        } else {
          const date = new Date(dragging.candidate.end);
          date.setMinutes(date.getMinutes() - 30);
          dragging.candidate.end = date;
          if (hasSearch) {
            this.$store.state.searchTimeEnd = convertStringTime(date);
          }
        }
      }
    };

    return (
      <div class="c-calendar__table">
        <div class="c-calendar__table-body">
          <div class="c-calendar__table-body__wrap" style="">
            <div class="c-calendar__table-th__wrap">
              {this.rooms.map((room) => {
                const isSelected = !!this.$store.state.candidateRooms.find((r) => r && r.selected && r.id === room.id);
                return (
                  <div class={['c-calendar__table-th', { 'is-selected': isSelected }]}>
                    <p class="c-calendar__table-conference">{room.name}</p>
                    <p class="c-calendar__table-people">
                      <span class="c-calendar__table-people__number">{room.capacity}</span>人
                    </p>
                  </div>
                );
              })}
            </div>
            <div
              ref="spaces"
              class="spaces js_vue_drag_scroll_wrap"
              style=""
              onScroll={(e) => {
                if (dragging.isMoved) return e.preventDefault();
              }}
            >
              <div class="js_vue_drag_scroll_child">
                {this.rooms.map((room) => {
                  const isSelected = !!this.$store.state.candidateRooms.find((r) => r && r.selected && r.id === room.id);
                  return (
                    <div
                      ref="room"
                      data-name="room"
                      class={['c-calendar__table-main__box', { 'is-selected': isSelected }]}
                      style="display:inline-block;"
                      vOn:touchstart__passive={(e) => {
                        this.canRender = false;
                        handleTouchStart(e, room);
                        window.requestAnimationFrame(this.handleRequestAnimationFrame);
                      }}
                      vOn:touchmove__passive={(e) => {
                        if (!this.canRender) return;
                        this.canRender = false;
                        handleTouchMove(e, room);
                        window.requestAnimationFrame(this.handleRequestAnimationFrame);
                      }}
                      vOn:touchend__passive={(e) => handleTouchEnd(e, room)}
                    >
                      <div ref="wrapper" class="c-calendar__table-td__wrap" style="position: relative;">
                        {Array(24)
                          .fill(null)
                          .map((empty, index) => {
                            const d = new Date(this.date);
                            d.setHours(index);
                            d.setMinutes(0);
                            d.setSeconds(0);
                            const half = new Date(d);
                            half.setMinutes(30);
                            return [<CalendarRoomSpace start={d} />, <CalendarRoomSpace start={half} />];
                          })
                          .flat()}
                        {this.$store.getters.findCandidates(room.id, this.date).map((candidate) => (
                          <CalendarSelectedRoomSpace candidate={candidate} />
                        ))}
                        {this.$store.getters.findEvents(room.id, this.date).list.map((event) => (
                          <CalendarEventSpace date={this.date} event={event} />
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};
