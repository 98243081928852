import CardCommonRoom from './common/CardCommonRoom';
import CardCommonEvent from './common/event/CardCommonEvent';
import CardCommonButton from './common/CardCommonButton';
import CardCommonEditButton from './common/CardCommonEditButton';
import inUseTtlImage from 'assets/in-use__ttl.png';

export default {
  name: 'CardInUsed',
  props: {
    event: {
      type: Object,
      required: true,
    },
    beforeLeave: {
      type: Function,
      required: true,
    },
    leave: {
      type: Function,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      edit: false,
    };
  },
  render() {
    const edit = () => {
      if (this.edit) {
        this.edit = false;
      } else {
        const editBtnActive = document.querySelector('.editActive .c-myreserve-edit__btn.is-active');
        editBtnActive && editBtnActive.click();
        const editBtnTentative = document.querySelector('.tentativeEditActive .c-myreserve-cancel__btn');
        editBtnTentative && editBtnTentative.click();
        this.edit = !this.edit;
      }
      this.$store.state.editInUsedMode = true;
    };
    const transitionEvent = this.$store.state.tentativeUpdateInUsedId;
    return (
      <transition name="standByList" tag="div" leave-active-class="animated fadeOutLeft" onBefore-leave={() => this.beforeLeave(this.$el)} onAfter-leave={this.leave}>
        {transitionEvent !== this.event.eventId && (
          <div class={['c-myreserve-box is-inuse', this.edit && 'editActive']}>
            <p class="c-myreserve-cancel__btn" />
            <CardCommonEditButton onClick={edit} editable={this.edit} />
            <div class="c-myreserve-ttl__wrap">
              <p class="c-myreserve-main__ttl">
                <img src={inUseTtlImage} alt="InUsed" style="display: block; width: 58px; margin: auto;" />
              </p>
              <p class="c-myreserve-sub__ttl">使用中</p>
            </div>
            <div class="c-myreserve__sub">
              <CardCommonEvent event={this.event} index={this.index} editable={this.edit} type="使用中" />
              <CardCommonRoom event={this.event} index={this.index} editable={this.edit} />
              <CardCommonButton event={this.event} status="inUsed" index={this.index} editable={this.edit} />
            </div>
          </div>
        )}
      </transition>
    );
  },
};
