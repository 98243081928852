import dragging from 'states/dragging';
import TheTabs from './TheTabs';
import TheModalSignOut from './TheModalSignOut';
import TheModalReservation from './roomReservation/reservation/TheModalReservation';
import TheModalCompleteReserve from './roomReservation/reservation/TheModalCompleteReserve';
import TheModalConnectionLoading from './TheModalConnectionLoading';
import TheLoading from 'components/TheLoading';

export default {
  name: 'TheRoot',
  data: () => {
    return {
      reset: false,
      rooms: [],
      tags: [],
      vStore: [],
    };
  },
  mounted() {
    this.addTimer();
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  methods: {
    show() {
      this.$modal.show('TheModalSignOut');
    },
    addTimer() {
      this.timer = setTimeout(() => {
        this.$store.dispatch('loadEvents', { date: this.$store.state.defaultDate || Date.now(), days: 7 }).then(this.addTimer);
      }, 30000);
    },
    handleTouchEnd() {
      if (!dragging.candidate) return clearTimeout(dragging.timer);
      dragging.reset();
      this.$store.commit('clearRecommends');
    },
  },
  render() {
    return (
      <div id="app" onTouchend={this.handleTouchEnd} onTouchcancel={this.handleTouchEnd}>
        <div class="c-tabpanel_wrap u-conference">
          <TheTabs ref="tabs" />
        </div>
        <router-view onClick={this.show} />
        <TheModalSignOut />
        <TheModalReservation />
        <TheModalCompleteReserve />
        <TheModalConnectionLoading />
        <TheLoading />
      </div>
    );
  },
};
