/**
 *
 * @param date
 * @return {string}
 */
export const createTimeLabelString = (date) => {
  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};

/**
 *
 * @param date
 * @return {string}
 */
export const createTimeValueString = (date) => {
  return `${date.getHours()}:${date.getMinutes()}`;
};
