export default {
  name: 'TheModalConnectionLoading',
  render() {
    return (
      <modal class="p-modal__loading" name="TheModalConnectionLoading" width="350px" height="110px" pivotX={0.5} pivotY={0} clickToClose={false}>
        <div class="loading">
          <span>
            現在通信を行っております。
            <br />
            少々お待ちください。
          </span>
        </div>
      </modal>
    );
  },
};
