import TheSearchByGuestsSelectedGuestList from './TheSearchByGuestsSelectedGuestList';
import SelectGuestBox from 'components/SelectGuestBox';
import calendarEvents from 'states/calendarEvents';

export default {
  name: 'TheSearchByGuests',
  data() {
    return {
      candidateList: [],
      gs: [],
      list: 0,
    };
  },
  computed: {
    guests() {
      const list = this.gs.map((g) => g.componentOptions.propsData.calendarEvent.calendarId);
      const ngs = this.$store.state.guests.map((calendarEvent) => {
        const index = list.indexOf(calendarEvent.calendarId);
        if (index === -1) {
          return <TheSearchByGuestsSelectedGuestList calendarEvent={calendarEvent} onClick={this.onClick} />;
        }
        return this.gs[index];
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (ngs.length !== this.gs.length || new Set([...ngs, ...this.gs]).size !== ngs.length + this.gs.length) this.gs = ngs;
      return this.gs;
    },
  },
  mounted() {
    this.$refs.acContent1.style.height = this.$refs.acContent1.firstElementChild.offsetHeight + 'px';
  },
  methods: {
    onInput(input) {
      if (!this.$store.state.guests.some((ce) => ce.calendarId === input.email)) {
        const target = calendarEvents.get(input.email, input.name);
        this.$store.commit('guests', target);
        this.putHeight();
      }
    },
    putHeight() {
      const target = this.$el.querySelector('.c-guest-join__output li');
      if (!target) {
        this.$el.querySelector('.te').style.height = '195px';
      } else if (this.list < 2) {
        const height = target.offsetHeight;
        const teHeight = this.$el.querySelector('.te');
        setTimeout(() => (teHeight.style.height = Number(teHeight.offsetHeight) + height + 'px'));
      }
    },
    reduceHeight() {
      const target = this.$el.querySelector('.c-guest-join__output li');
      if (this.list === 1) {
        const height = target.offsetHeight;
        const teHeight = this.$el.querySelector('.te');
        setTimeout(() => (teHeight.style.height = Number(teHeight.offsetHeight) - height + 'px'));
      }
      if (this.list === 2) {
        this.$el.querySelector('.te').style.height = '195px';
      }
    },
    onClick() {
      this.reduceHeight();
    },
  },
  render() {
    this.list = this.$store.state.guests.length;
    const checked = this.$store.state.guests.length > 0 || (this.$refs.checkbox && this.$refs.checkbox.checked);
    return (
      <div class="p-guest-join__wrap">
        <div class="c-modal__reserve-sub__list ac">
          <input id="ac1" type="checkbox" checked={checked} />
          <label for="ac1" class={['c-guest-join__ttl', 'c-modal-ttl', { 'is-active': checked }]} id="labelGuest" onClick={this.$listeners.click}>
            ゲストの予定から検索
          </label>
          <div ref="acContent1" class="te" onTransitionstart={(e) => e.target.classList.remove('te')} onTransitionend={(e) => e.target.classList.add('te')}>
            <div class="c-modal__reserve-sub__list-item">
              <p class="c-modal-content__sub-ttl">ゲストの予定</p>
              <div class="p-guest-join__box">
                <SelectGuestBox ref="select" class="c-guest-join__input" onInput={(e) => this.onInput(e)} />
                <ul class="c-guest-join__output">{this.guests}</ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};
