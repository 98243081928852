import axios from 'axios';
import qs from 'qs';
import token from './token';

const request = async (path, method, query, cache = false, cacheKey) => {
  const accessToken = await token.get();
  const url = method !== 'GET' || !query ? path : `${path}?${qs.stringify(query)}`;
  const data = method !== 'GET' ? query : undefined;
  return axios({
    method,
    url: `${API_SERVER_ADDRESS}/api/v1/${url}`,
    data,
    headers: {
      Authorization: accessToken,
      Accept: 'application/json',
    },
  }).then((r) => {
    if (cache) {
      const hashKey = cacheKey + '-hash';
      const currentHash = localStorage.getItem(hashKey);
      if (currentHash !== r.headers['x-asmee-hash'] && r.data.success) {
        localStorage.setItem(hashKey, r.headers['x-asmee-hash']);
        localStorage.setItem(cacheKey, JSON.stringify(r.data));
      }
    }
    return r.data;
  });
};

export const getToken = async (code) => {
  return axios({
    method: 'post',
    url: `${API_SERVER_ADDRESS}/api/v1/auth`,
    data: { code },
    headers: { Accept: 'application/json' },
  }).then((r) => {
    return r.data;
  });
};

export const getRooms = () => request('room/list', 'GET', undefined, true, 'rooms');
export const getTags = () => request('tag/list', 'GET', undefined, true, 'tags');
export const getStaffs = () => request('staff/list', 'GET', undefined, true, 'staffs');

export const getSchedules = (params) => request('calendars', 'GET', params);
export const postSchedules = (params) => request('calendars', 'POST', params);
export const postMeet = (params) => request('calendars/meet', 'POST', params);
export const putSchedules = (params) => request('calendars', 'PUT', params);
export const getMySchedules = () => request('calendars/my', 'GET', undefined, true, 'mySchedule');

export const getHasSchedule = (params) => request('calendars/has', 'GET', params);

export const getOptions = () => request('option/list', 'GET', undefined, true, 'options');
