import * as server from 'network/server';
import { sha1 } from '../../utils/crypt';
import { findCalenderEvents } from 'utils/findCalenderEvent';
import { findCalenderTentativeEv } from 'utils/findCalenderTentativeEv';
import CardInUsed from './card/CardInUsed';
import CardStandBy from './card/CardStandBy';
import CardTentative from './card/CardTentative';

let timer;

export default {
  name: 'TheCards',
  data() {
    return {
      isVerticalAnimate: false,
      isExistsAnimationContent: false,
      canConnect: true,
      myScheduleHash: '',
    };
  },
  created() {
    this.getMySchedules(true).then(() => {
      if (this.canConnect) this.createTimer();
    });
  },
  destroyed() {
    this.clearTimer();
  },
  methods: {
    getMySchedules(created = false) {
      return server.getMySchedules().then(async (r) => {
        const hash = await sha1(JSON.stringify(r.data));
        if (hash === this.myScheduleHash && !created) return;
        this.myScheduleHash = hash;

        this.$set(this.$store.state, 'my', r.data);
        const arrKey = Object.keys(r.data);
        const mySchedulesArr = [];
        for (let i = 0; i < arrKey.length; i++) {
          const dup = mySchedulesArr.find((n) => n.key === arrKey[i]);
          if (!dup) mySchedulesArr.push({ schedule: r.data[arrKey[i]], uuid: arrKey[i] });
        }
        const reservationList = mySchedulesArr.filter((ev) => ev.schedule.events[0].status === '予約');
        const tentativeList = mySchedulesArr.filter((ev) => ev.schedule.events[0].status === '仮予約');
        const jsonReservationList = JSON.stringify(reservationList);
        const jsonTentativeList = JSON.stringify(tentativeList);
        const inUsedList = mySchedulesArr.filter((ev) => ev.schedule.events[0].status === '使用中');
        if (this.$store.state.storeInusedEvents && !inUsedList.length) this.$store.state.storeInusedEvents = '';
        await findCalenderEvents('inUsed', inUsedList);
        if (jsonReservationList !== this.$store.state.stockJsonReserve) {
          this.$store.state.storeReservationEvents = [];
          this.$store.state.stockJsonReserve = jsonReservationList;
          Promise.all([reservationList]).then((r) => r.map((ev) => findCalenderEvents('reserve', ev)));
          this.$store.state.planTxtNone = !reservationList.length;
        }
        if (jsonTentativeList !== this.$store.state.stockJsonTentative) {
          this.$store.state.storeTentativeEvents = [];
          this.$store.state.stockJsonTentative = jsonTentativeList;
          Promise.all([tentativeList]).then((r) => findCalenderTentativeEv(r[0]));
        }
      });
    },
    createTimer() {
      this.canConnect = true;
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.getMySchedules(false).then(() => {
          if (this.canConnect) this.createTimer();
        });
        this.$forceUpdate();
      }, 10000);
    },
    clearTimer() {
      clearTimeout(timer);
      this.canConnect = false;
    },
    beforeLeaveCard(el) {
      if (!el.nextElementSibling) return;
      el.nextElementSibling.classList.add('verticalSliding');
      document.documentElement.style.setProperty('--card-vertical-animation-size', el.offsetHeight + 'px');
      this.isExistsAnimationContent = true;
      this.isVerticalAnimate = false;
      this.clearTimer();
    },
    leaveCard() {
      if (this.isExistsAnimationContent) {
        this.isVerticalAnimate = true;
      } else {
        this.createTimer();
      }
      this.isExistsAnimationContent = false;
    },
    animationEnd(e) {
      if (e.target?.classList.contains('verticalSliding')) {
        e.target.classList.remove('verticalSliding');
        this.createTimer();
        this.isVerticalAnimate = false;
        this.$forceUpdate();
      }
    },
  },
  render() {
    const inUsed = this.$store.state.storeInusedEvents;
    const waitingEv = this.$store.state.storeReservationEvents;

    const my = this.$store.state.my;
    const items = Object.keys(my)
      .map((uuid) => ({ ...my[uuid], uuid }))
      .filter((schedule) => schedule.events[0].status !== '使用中')
      .sort((a, b) => {
        const ad = a.events.map((event) => new Date(event.start_time.replace(/-/g, '/'))).sort((ea, eb) => (ea < eb ? -1 : 1))[0];
        const bd = b.events.map((event) => new Date(event.start_time.replace(/-/g, '/'))).sort((ea, eb) => (ea < eb ? -1 : 1))[0];
        return ad.getTime() === bd.getTime() ? 0 : ad < bd ? -1 : 1;
      })
      .map((schedule) => {
        if (schedule.events[0].status === '予約') {
          return this.$store.state.storeReservationEvents.find((r) => {
            return r.calendar_id === schedule.events[0].calendar_id;
          });
        } else {
          return this.$store.state.storeTentativeEvents.find((t) => {
            return t.mySch.uuid === schedule.uuid;
          });
        }
      })
      .filter(Boolean);

    if (inUsed) {
      waitingEv.some((standEv) => {
        if (standEv.calendar_id !== inUsed.calendar_id) {
          return false;
        }
        this.$store.commit('updateCalenderscedules', { type: 'setInUsed', data: standEv.calendar_id });
        return true;
      });
    }
    return (
      <div class="p-myreserve detail-list">
        {this.$store.state.planTxtNone && <p style="margin: 100px 0 2rem 30px; font-size: 20px;">次の予定はありません。</p>}
        {inUsed && <CardInUsed event={inUsed} index={-1} beforeLeave={this.beforeLeaveCard} leave={this.leaveCard} onAnimationend={this.animationEnd} />}
        <div ref="cards" class={['p-myreserve', { verticalAnimate: this.isVerticalAnimate }]} onAnimationend={this.animationEnd}>
          {items.map((item, index) => {
            return item.status === '予約' ? (
              <CardStandBy event={item} index={index} beforeLeave={this.beforeLeaveCard} leave={this.leaveCard} />
            ) : (
              <CardTentative event={item} index={index} beforeLeave={this.beforeLeaveCard} leave={this.leaveCard} />
            );
          })}
        </div>
      </div>
    );
  },
};
