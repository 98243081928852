const labels = [
  { colorId: 11, color: '#d50001', colorIndex: '69', text: 'トマト', tabIndex: '', role: 'menuitem', active: 'on' },
  { colorId: 4, color: '#E67C73', colorIndex: '68', text: 'フラミンゴ', tabIndex: '0', role: 'menuitem', active: 'off' },
  { colorId: 6, color: '#f4521d', colorIndex: '70', text: 'ミカン', tabIndex: '0', role: 'menuitem', active: 'off' },
  { colorId: 5, color: '#F6BF26', colorIndex: '78', text: 'バナナ', tabIndex: '0', role: 'menuitem', active: 'off' },
  { colorId: 2, color: '#33B679', colorIndex: '79', text: 'セージ', tabIndex: '0', role: 'menuitem', active: 'off' },
  { colorId: 10, color: '#0a8043', colorIndex: '74', text: 'バジル', tabIndex: '0', role: 'menuitem', active: 'off' },
  { colorId: 7, color: '#039BE5', colorIndex: '79', text: 'ピーコック', tabIndex: '-1', role: 'menuitem', active: 'off' },
  { colorId: 9, color: '#3F51B5', colorIndex: '82', text: 'ブルーベリー', tabIndex: '0', role: 'menuitem', active: 'off' },
  { colorId: 1, color: '#7a86cb', colorIndex: '83', text: 'ラベンダー', tabIndex: '0', role: 'menuitem', active: 'off' },
  { colorId: 3, color: '#8e24aa', colorIndex: '89', text: 'ブドウ', tabIndex: '0', role: 'menuitem', active: 'off' },
  { colorId: 8, color: '#616161', colorIndex: '85', text: 'グラファイト', tabIndex: '0', role: 'menuitem', active: 'off' },
];

export default labels;

export const findColorById = (id) => {
  return labels.find(({ colorId }) => colorId === id) || {};
};

export const findColorByCode = (code) => {
  return labels.find(({ color }) => color === code) || {};
};
