import parseDate from './parseDate';

/**
 * YYYY-MM-DD HH:II:SS
 * @param {Date} date
 * @returns {string}
 */
export default function (date) {
  const p = parseDate(date);
  return `${p.year}-${p.month}-${p.date} ${p.hours}:${p.minutes}:${p.seconds}`;
}
