export default function () {
  const now = new Date();
  const min = now.getMinutes();

  if (min % 15 === 0) {
    now.setMinutes(now.getMinutes() + 15);
  } else {
    const diff60 = 60 - min;
    if (diff60 > min) {
      now.setMinutes(diff60 > 45 ? 15 : 30);
    } else {
      now.setMinutes(diff60 > 15 ? 45 : 60);
    }
  }

  return now;
}
